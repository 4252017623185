import React from "react";
import "./programs.css";
import programsData from "../../data/programsData";
import rightArrow from "../../assets/rightArrow.png";
const Programs = () => {
  return (
    <div className="Progrsms" id="programs">
      <div className="programs-header">
        <span className="stroke-text">Explore Our</span>
        <span>Programs</span>
        <span className="stroke-text">To Shape you body</span>
      </div>

      <div className="categories">
        {programsData.map((program) => {
          return (
            <div className="category">
              {program.image}
              <span>{program.heading}</span>
              <span>{program.details}</span>

              <div className="join-now">
                <span>Join now</span>
                <img src={rightArrow} alt="" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Programs;
