import React, { useRef } from "react";
import "./Join.css";
import emailjs from "@emailjs/browser";

const Join = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_4a4iied",
        "template_e6c2gns",
        form.current,
        "L65fP1jkFx3lr9Trr"
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div className="join-container" id="join-us">
      <div className="join-l">
        <hr />
        <div>
          <span className="stroke-text">ready to</span>
          <span>level up</span>
        </div>
        <div>
          <span>your body</span>
          <span className="stroke-text">with us?</span>
        </div>
      </div>
      <div className="join-r">
        <div className="form-div">
          <form className="form-class" ref={form} onSubmit={sendEmail}>
            <input
              type="email"
              name="user_email"
              placeholder="Enter you email"
            />
            <button type="submit" className="btn btn-j">
              Join now
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Join;
