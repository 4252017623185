import React from "react";
import "./Plans.css";
import plansData from "../../data/plansData";
import whiteTick from "../../assets/whiteTick.png";

const Plans = () => {
  return (
    <div className="plans-container">
      <div className="blur plans-blur-1"></div>
      <div className="blur plans-blur-2"></div>
      <div className="programs-header">
        <span className="stroke-text">Ready to start</span>
        <span>Your journey</span>
        <span className="stroke-text">With us</span>
      </div>

      <div className="plans-card" id="plans">
        {plansData.map((plan) => {
          return (
            <div className="single-plan">
              {plan.icon}
              <div>{plan.name}</div>
              <div>$ {plan.price}</div>

              <div className="features">
                {plan.features.map((feature, i) => {
                  return (
                    <div className="feature" key={i}>
                      <img src={whiteTick} alt="" />
                      <span>{feature}</span>
                    </div>
                  );
                })}
              </div>
              <div className="more-benefits">
                <span>see more benifits -{">"} </span>
              </div>
              <div className="join-btn">
                <button className="btn">Join now</button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Plans;
